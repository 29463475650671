<template>
  <nav class="relative flex flex-col justify-between h-full py-3 px-2 space-y-1 bg-mm-colors-sidebarBackground" aria-label="Sidebar">
    <!-- AI Calls -->
    <section v-show="isAtLeastRoleEditor()">
      <NavDropdown default-route="ai-summary-notifications" button-name="Summaries" :named-routes="['ai-summary-notifications', 'ai-summary-notifications-create', 'ai-summary-notifications-edit']">
        <template #icon>
          <MIcon icon="IconRadarFilled" />
        </template>
      </NavDropdown>
      <!-- Autopilot Extractions -->
      <NavDropdown
        default-route="autopilot-extractions"
        button-name="Autopilot Suite"
        :named-routes="['autopilot-extractions', 'autopilot-extraction-create', 'autopilot-extraction-edit', 'autopilot-batch', 'autopilot-batch-create', 'autopilot-batch-view', 'retro-pilot', 'retro-pilot-create', 'retro-pilot-view']"
      >
        <template #icon>
          <MIcon icon="IconAutopilotExtractions" />
        </template>
        <router-link :to="{ name: 'autopilot-extractions' }" class="nav-link text-gray-400" :class="[setActiveLink(['autopilot-extractions', 'autopilot-extraction-create', 'autopilot-extraction-edit'])]">
          Autopilot Classic
        </router-link>
        <CBadge v-show="features.autopilotBatchWizardAccess" display-flex>
          <router-link :to="{ name: 'autopilot-batch' }" class="nav-link text-gray-400" :class="[setActiveLink(['autopilot-batch', 'autopilot-batch-create', 'autopilot-batch-view'])]">
            Autopilot Batch
          </router-link>
        </CBadge>
        <CBadge v-show="features.retroPilotWizard" display-flex>
          <router-link :to="{ name: 'retro-pilot' }" class="nav-link text-gray-400" :class="[setActiveLink(['retro-pilot', 'retro-pilot-create', 'retro-pilot-view'])]">
            Retropilot
          </router-link>
        </CBadge>
      </NavDropdown>
      <!-- AI Signals -->
      <NavDropdown
        default-route="ai-signals"
        button-name="AI Signals"
        :named-routes="['ai-signals', 'ai-signals-create', 'ai-signals-edit']"
      >
        <template #icon>
          <MIcon icon="IconBolt" />
        </template>
      </NavDropdown>
      <!-- AI Playground -->
      <NavDropdown
        v-show="features.aiPlaygroundWizard"
        default-route="ai-playground"
        button-name="AI Playground"
        :named-routes="['ai-playground', 'ai-playground-create', 'ai-playground-edit']"
      >
        <template #icon>
          <MIcon icon="IconNavaidVhfor" />
        </template>
      </NavDropdown>
      <!-- Contact Call Automations -->
      <NavDropdown
        v-show="features.contactAutomationWizard"
        default-route="contact-automations"
        button-name="Contact Automation"
        :named-routes="['contact-automations', 'contact-automations-create', 'contact-automations-edit']"
      >
        <template #icon>
          <MIcon icon="IconUsers" />
        </template>
      </NavDropdown>
      <!-- Salesforce Notifications -->
      <NavDropdown default-route="flow-notifications" button-name="Salesforce Notifications" :named-routes="['flow-notifications', 'flow-notifications-create', 'flow-notifications-edit', 'gif-home', 'gif-edit', 'gif-search', 'gif-add', 'scheduled-notifications', 'scheduled-notification-create', 'scheduled-notification-edit', 'scheduled-reports', 'scheduled-reports-create', 'scheduled-reports-edit']">
        <template #icon>
          <MIcon icon="IconSalesforce" />
        </template>
        <router-link :to="{ name: 'flow-notifications' }" class="nav-link text-gray-400" :class="[setActiveLink(['flow-notifications', 'flow-notifications-create', 'flow-notifications-edit', 'gif-home', 'gif-edit', 'gif-search', 'gif-add'])]">
          Notifications
        </router-link>
        <router-link :to="{ name: 'scheduled-notifications' }" class="nav-link text-gray-400" :class="[setActiveLink(['scheduled-notifications', 'scheduled-notification-create', 'scheduled-notification-edit'])]">
          Scheduled Notifications
        </router-link>
        <router-link :to="{ name: 'scheduled-reports' }" class="nav-link text-gray-400" :class="[setActiveLink(['scheduled-reports', 'scheduled-reports-create', 'scheduled-reports-edit'])]">
          Scheduled Reports
        </router-link>
      </NavDropdown>
      <NavDropdown
        v-show="features.Briefs_Enabled"
        beta-badge
        default-route="briefs"
        button-name="Briefs"
        :named-routes="['briefs', 'briefs-create', 'briefs-edit']"
      >
        <template #icon>
          <MIcon icon="IconDocumentText" />
        </template>
      </NavDropdown>
      <NavDropdown
        v-show="features.emailFollowUp"
        beta-badge
        default-route="admin-email-follow-up-config"
        button-name="Email Follow-Up"
        :named-routes="['admin-email-follow-up-config']"
      >
        <template #icon>
          <MIcon icon="IconEmailFollowUp" />
        </template>
      </NavDropdown>
      <!-- Slack Automation -->
      <NavDropdown default-route="admin-settings-rooms" button-name="Slack Automation" :named-routes="['admin-settings-rooms', 'admin-settings-rooms-edit', 'admin-settings-rooms-create', 'admin-settings-dealrooms','admin-settings-playbook', 'admin-settings-accounts', 'slack-home-screen-configuration']">
        <template #icon>
          <MIcon icon="IconSlack" />
        </template>
        <router-link :to="{ name: 'admin-settings-rooms' }" class="nav-link text-gray-400" :class="[setActiveLink(['admin-settings-rooms'])]">
          Slack Room Automations
        </router-link>
        <router-link :to="{ name: 'admin-settings-dealrooms' }" class="nav-link text-gray-400" :class="[setActiveLink(['admin-settings-dealrooms'])]">
          Deal Rooms
        </router-link>
        <router-link :to="{ name: 'admin-settings-playbook' }" class="nav-link text-gray-400" :class="[setActiveLink(['admin-settings-playbook'])]">
          Playbook
        </router-link>
        <router-link :to="{ name: 'admin-settings-accounts' }" class="nav-link text-gray-400" :class="[setActiveLink(['admin-settings-accounts'])]">
          Account Rooms
        </router-link>
      </NavDropdown>
      <!-- Advanced -->
      <NavDropdown 
        default-route="workflows-home" 
        button-name="Advanced Workflows" 
        :named-routes="[ 
          'workflows-home',
          'workflows-create',
          'workflows-edit',
          'workflows-create-advanced',
          'workflows-create-task',
          'workflows-create-notification',
        ]"
      >
        <template #icon>
          <MIcon icon="IconDealAssist" />
        </template>
      </NavDropdown>
      <!-- Analytics -->
      <NavDropdown
        v-show="state.featureToggles['ui.analytics.v1.beta']"
        beta-badge
        default-route="analytics-home"
        button-name="Analytics"
        :named-routes="['analytics-home']"
      >
        <template #icon>
          <MIcon icon="IconAnalytics" />
        </template>
      </NavDropdown>
      <NavDropdown
        v-show="features.Coaching_Enabled"
        default-route="admin-coaching-settings-general"
        button-name="Coaching"
        :named-routes="['admin-coaching-settings-general', 'admin-coaching-settings-salesforce-mapping']"
      >
        <template #icon>
          <MIcon icon="IconRocketLaunch" />
        </template>
      </NavDropdown>
    </section>
    <!-- This will be at the bottom -->
    <section>
      <div v-show="isAtLeastRoleEditor()">
        <router-link class="sidebar-button focus:text-mm-colors-textPrimary pl-4" :to="{ name: 'pipeline' }" :class="[setActiveLink(['pipeline'], 'title')]">
          <span class="mr-2 w-6"><MIcon icon="IconViewList" /></span>
          Execution Logs
        </router-link>
      </div>
      <!-- Debug meetings -->
      <NavDropdown v-show="isExactRoleOrganizationAdmin()" default-route="admin-meetings" button-name="Debug Console" :named-routes="['admin-meetings', 'admin-meeting', 'call-audit', 'admin-meetings-report', 'extraction-navigator']">
        <template #icon>
          <MIcon icon="IconCalendarDays" class="h-6 w-6 shrink-0" />
        </template>
        <router-link :to="{ name: 'admin-meetings' }" class="nav-link text-gray-400" :class="[setActiveLink(['admin-meetings'])]">
          Meeting Diagnostics
        </router-link>
        <router-link :to="{ name: 'call-audit' }" class="nav-link text-gray-400" :class="[setActiveLink(['call-audit'])]">
          Call Audit
        </router-link>
        <router-link :to="{ name: 'admin-meetings-report'}" class="nav-link text-gray-400" :class="[setActiveLink(['admin-meetings-report'])]">
          Meeting Report
        </router-link>
        <router-link
          v-show="features.Extraction_Navigator_Enabled"
          :to="{ name: 'extraction-navigator'}"
          class="nav-link text-gray-400"
          :class="[setActiveLink(['extraction-navigator'])]"
        >
          Extraction Navigator
        </router-link>
      </NavDropdown>
      <div v-show="isAtLeastRoleEditor()">
        <router-link class="sidebar-button focus:text-mm-colors-textPrimary pl-4" :to="{ name: 'integrations-home' }" :class="[setActiveLink(['integrations-home'], 'title')]">
          <span class="mr-2 w-6">
            <MIcon icon="IconRouteFilled" />
          </span>
          Integrations
        </router-link>
      </div>
      <NavDropdown v-show="isAtLeastRoleUserAdmin()" default-route="team" button-name="Team" :named-routes="['team']">
        <template #icon>
          <MIcon icon="IconUsersGroupFilled" />
        </template>
      </NavDropdown>
      <NavDropdown v-show="isAtLeastRoleEditor()" default-route="admin-settings-momentum-ai" button-name="Settings" :named-routes="['admin-settings-momentum-ai', 'admin-settings-security', 'admin-settings-salesforce', 'admin-settings-google-drive', 'admin-settings-zoom', 'admin-company-info', 'admin-settings-consent']">
        <template #icon>
          <MIcon icon="IconCog" />
        </template>
        <router-link :to="{ name: 'admin-settings-momentum-ai' }" class="nav-link text-gray-400" :class="[setActiveLink(['admin-settings-momentum-ai'])]">
          Momentum AI
        </router-link>
        <router-link :to="{ name: 'admin-company-info' }" class="nav-link text-gray-400" :class="[setActiveLink(['admin-company-info'])]">
          Company Info
        </router-link>
        <router-link v-show="isExactRoleOrganizationAdmin()" :to="{ name: 'admin-settings-consent'}" class="nav-link text-gray-400" :class="[setActiveLink(['admin-settings-consent'])]">
          Recording Consent
        </router-link>
        <router-link v-show="isExactRoleOrganizationAdmin()" :to="{ name: 'admin-settings-security'}" class="nav-link text-gray-400" :class="[setActiveLink(['admin-settings-security'])]">
          Security
        </router-link>
        <NavDropdown button-name="Integrations" style="padding-left: 0.5rem;" :named-routes="['admin-settings-google-drive', 'admin-settings-salesforce', 'admin-settings-zoom']">
          <router-link v-show="state.integrationIsAuthenticated['GOOGLE_DRIVE']" :to="{ name: 'admin-settings-google-drive'}" class="nav-link text-gray-400" :class="[setActiveLink(['admin-settings-google-drive'])]">
            Google Drive
          </router-link>
          <router-link :to="{ name: 'admin-settings-salesforce' }" class="nav-link text-gray-400" :class="[setActiveLink(['admin-settings-salesforce'])]">
            Salesforce
          </router-link>
          <router-link v-show="state.integrationIsAuthenticated['ZOOM']" :to="{ name: 'admin-settings-zoom'}" class="nav-link text-gray-400" :class="[setActiveLink(['admin-settings-zoom'])]">
            Zoom
          </router-link>
        </NavDropdown>
      </NavDropdown>
      <NavDropdown v-show="isLocalEnvironment()" default-route="demo-field-paths" button-name="Component Demos" :named-routes="['demo-field-paths', 'demo-condition-builder', 'demo-cron-scheduler', 'demo-sql-filter-builder', 'demo-select-field-model', 'demo-icons']">
        <template #icon>
          <MIcon icon="IconFolderMinus" />
        </template>
        <router-link :to="{ name: 'demo-field-paths' }" class="nav-link text-gray-400" :class="[setActiveLink(['demo-field-paths'])]">
          Field Paths
        </router-link>
        <router-link :to="{ name: 'demo-condition-builder' }" class="nav-link text-gray-400" :class="[setActiveLink(['demo-condition-builder'])]">
          Condition Builder
        </router-link>
        <router-link :to="{ name: 'demo-cron-scheduler' }" class="nav-link text-gray-400" :class="[setActiveLink(['demo-cron-scheduler'])]">
          Cron Scheduler
        </router-link>
        <router-link :to="{ name: 'demo-select-field-model' }" class="nav-link text-gray-400" :class="[setActiveLink(['demo-select-field-model'])]">
          Field Select Modal
        </router-link>
        <router-link :to="{ name: 'demo-sql-filter-builder' }" class="nav-link text-gray-400" :class="[setActiveLink(['demo-sql-filter-builder'])]">
          SQL Filter Builder
        </router-link>
        <router-link :to="{ name: 'demo-icons' }" class="nav-link text-gray-400" :class="[setActiveLink(['demo-icons'])]">
          Icons
        </router-link>
        <router-link v-show="isSupportConsole" :to="{ name: 'momentum-support-fake-meeting'}" class="nav-link text-gray-400" :class="[setActiveLink(['momentum-support-fake-meeting'])]">
          Fake Meeting
        </router-link>
      </NavDropdown>
      <NavDropdown 
        v-show="isLocalEnvironment()" 
        default-route="library-alert" 
        button-name="Library" 
        :named-routes="[
          'library-alert',
          'library-button',
          'library-card',
          'library-checkbox',
          'library-confirm-action',
          'library-draggable',
          'library-editor',
          'library-input',
          'library-number-input',
          'library-radio',
          'library-select',
          'library-switch',
          'library-table',
          'library-tabs',
          'library-tags',
          'library-textarea'
        ]"
      >
        <template #icon>
          <MIcon icon="IconAccountBalanceOutlined" />
        </template>
        <router-link :to="{ name: 'library-alert' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-alert'])]">
          Alert
        </router-link>
        <router-link :to="{ name: 'library-button' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-button'])]">
          Button
        </router-link>
        <router-link :to="{ name: 'library-card' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-card'])]">
          Card
        </router-link>
        <router-link :to="{ name: 'library-checkbox' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-checkbox'])]">
          Checkbox
        </router-link>
        <router-link :to="{ name: 'library-confirm-action' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-confirm-action'])]">
          Confirm Action
        </router-link>
        <router-link :to="{ name: 'library-draggable' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-draggable'])]">
          Draggable
        </router-link>
        <router-link :to="{ name: 'library-drawer' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-drawer'])]">
          Drawer
        </router-link>
        <router-link :to="{ name: 'library-editor' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-editor'])]">
          Editor
        </router-link>
        <router-link :to="{ name: 'library-empty-state' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-empty-state'])]">
          Empty State
        </router-link>
        <router-link :to="{ name: 'library-input' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-input'])]">
          Input
        </router-link>
        <router-link :to="{ name: 'library-loading-state' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-loading-state'])]">
          Loading State
        </router-link>
        <router-link :to="{ name: 'library-modal' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-modal'])]">
          Modal
        </router-link>
        <router-link :to="{ name: 'library-number-input' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-number-input'])]">
          Number Input
        </router-link>
        <router-link :to="{ name: 'library-radio' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-radio'])]">
          Radio
        </router-link>
        <router-link :to="{ name: 'library-refresh-button' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-refresh-button'])]">
          Refresh Button
        </router-link>
        <router-link :to="{ name: 'library-select' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-select'])]">
          Select
        </router-link>
        <router-link :to="{ name: 'library-switch' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-switch'])]">
          Switch
        </router-link>
        <router-link :to="{ name: 'library-table' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-table'])]">
          Table
        </router-link>
        <router-link :to="{ name: 'library-tabs' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-tabs'])]">
          Tabs
        </router-link>
        <router-link :to="{ name: 'library-tags' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-tags'])]">
          Tags
        </router-link>
        <router-link :to="{ name: 'library-textarea' }" class="nav-link text-gray-400" :class="[setActiveLink(['library-textarea'])]">
          Textarea
        </router-link>
      </NavDropdown>
    </section>
  </nav>
</template>

<script lang="ts">
import { ApolloProvider } from "@vue/apollo-option";
import { computed, defineComponent, inject, onMounted, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { OrganizationApi } from "../../api/organization";
import { TAuthUser } from "../../api/user/types";
import { isOrganizationIntegrationAuthenticated } from "../../composables/integrations";
import { getEnabledFeature } from "../../launch-darkly/get-enabled-feature";
import { useFeaturesStore } from "../../store/features";
import { IntegrationServiceEnum } from "../../types/integrations";
import { TOrganizationSettings } from "../../types/organization";
import { isLocalEnvironment } from "../../utils/common-js";
import { isAtLeastRoleEditor, isAtLeastRoleUserAdmin, isExactRoleOrganizationAdmin } from "../../utils/roles";
import { CBadge } from "../library";
import MIcon from "../reusable-components/MIcon.vue";
import NavDropdown from "./NavDropdown.vue";

export default defineComponent({
  components: {
    CBadge,
    MIcon,
    NavDropdown,
  },
  setup() {
    type TState = {
      accountRoomEnabled: boolean;
      authUser: TAuthUser;
      featureToggles: Record<string, boolean>;
      integrationIsAuthenticated: {
        [IntegrationServiceEnum.GOOGLE_DRIVE]: boolean;
        [IntegrationServiceEnum.ZOOM]: boolean;
      };
      settings: TOrganizationSettings;
    };

    const store: any = useStore();
    const route: any = useRoute();
    const isSupportConsole: boolean = inject("isSupportConsole");
    const apollo: ApolloProvider = inject<ApolloProvider>("apollo");
    const organizationApi = new OrganizationApi(apollo);
    const featuresStore = useFeaturesStore();
    const state: TState = reactive<TState>({
      accountRoomEnabled: false,
      authUser: null,
      featureToggles: {},
      integrationIsAuthenticated: {
        [IntegrationServiceEnum.GOOGLE_DRIVE]: false,
        [IntegrationServiceEnum.ZOOM]: false,
      },
      settings: null,
    });

    watch(
      () => store.state.authUser,
      (newValue) => {
        state.authUser = newValue;
        getEnabledFeatures(newValue);
      }
    );

    watch(
      () => store.state.orgData,
      (newValue) => {
        state.accountRoomEnabled = newValue.organizationSettings.settings.accountRoomEnabled;
      },
      {
        deep: true,
      }
    );

    onMounted(() => {
      if (store.state.authUser) {
        state.authUser = store.state.authUser;
        getEnabledFeatures(["app.insights", "ui.user.pools.beta", "ui.analytics.v1.beta"]);
        if (isAtLeastRoleEditor(store.state.authUser)) {
          fetchSettings();
        }
        integrationIsAuthenticated(IntegrationServiceEnum.GOOGLE_DRIVE);
        integrationIsAuthenticated(IntegrationServiceEnum.ZOOM);
      }
    });

    const features = computed(() => featuresStore.state.features);

    function setActiveLink(names: string[], type?: string): string {
      let style = "";
      for (const name of names) {
        if (name === route.name && type !== "title") {
          style = "!text-black";
          break;
        } else if (name === route.name && type === "title") {
          style = "bg-mm-colors-sidebarHighlight text-mm-colors-textPrimary";
          break;
        } else {
          style = "text-gray-600";
          break;
        }
      }

      return style;
    }

    async function getEnabledFeatures(flags: string[]): Promise<void> {
      for (const flag of flags) {
        state.featureToggles[flag] = await getEnabledFeature(flag, store.state.authUser);
      }
    }

    function fetchSettings(): Promise<void> {
      return organizationApi.get().then((result) => {
        state.settings = result.organizationSettings.settings;
        store.dispatch("setOrgData", result);
      });
    }

    function integrationIsAuthenticated(service: IntegrationServiceEnum): Promise<void> {
      return isOrganizationIntegrationAuthenticated(apollo, service)
        .then((isAuthenticated) => {
          state.integrationIsAuthenticated[service] = isAuthenticated;
        })
        .catch((error: Error) => {
          console.error(error.message);
          state.integrationIsAuthenticated[service] = false;
        });
    }

    return {
      features,
      fetchSettings,
      getEnabledFeatures,
      isAtLeastRoleEditor,
      isAtLeastRoleUserAdmin,
      isExactRoleOrganizationAdmin,
      isLocalEnvironment,
      isSupportConsole,
      setActiveLink,
      state,
    };
  },
});
</script>

<style>
.nav-link {
  @apply w-full flex items-center pl-10 pr-2 py-2 text-sm font-medium rounded-md outline-none focus:text-black focus:outline-none;
}

.rotate {
  transform: rotate(90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}
</style>
